import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AzLoadersModule } from '../shared/az-loaders';
import { AzTooltipComponent } from '../shared/az-tooltip/az-tooltip.component';
import { WeightComponent } from './form/weight/weight.component';
import { MatDialog } from '@angular/material/dialog';
import { BloodSugarComponent } from './form/blood-sugar/blood-sugar.component';
import { BloodPressureComponent } from './form/blood-pressure/blood-pressure.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from '../login/services/auth.services';
import { HomeService } from './services/home.services';
import { JsonResult } from '../shared/interfaces/json-result.interface';
import { Patient } from '../shared/interfaces/patient.interface';
import { HistorialComponent } from './historial/historial.component';
import { ScreenGlucoseComponent } from '../measure/screen-glucose/screen-glucose.component';
import { ScreenWeightComponent } from '../measure/screen-weight/screen-weight.component';
import { ScreenPresionComponent } from '../measure/screen-presion/screen-presion.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [AzLoadersModule, AzTooltipComponent, HttpClientModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit {
  loading: boolean = true;
  paciente!: Patient;

  constructor(
    public authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
    if (this.authService._isLogin == false) {
      this.router.navigateByUrl('/auth/login');
    }
    this.getDataPatient()
  }

  get getSex(): String {
    return this.paciente.genero == "M" ? 'mars' : this.paciente.genero == 'F' ? 'venus' : 'venus-mars';
  }

  getDataPatient(load = true) {
    this.loading = load;
    this.homeService.getDataPatient(this.authService._getuserInfo.idpaciente).subscribe((trae: JsonResult) => {
      this.paciente = trae.retObject;
      this.paciente.date_last_test_format = this.paciente.last_medition ? `${this.paciente.last_medition.date} ${this.paciente.last_medition.time}` : '';
      this.paciente.nombreCompleto = `${this.paciente.apellidopaterno}${this.paciente.apellidomaterno === '' ? ',' : ''} ${this.paciente.apellidomaterno != '' ? this.paciente.apellidomaterno + ',' : ''} ${this.paciente.nombre}`;

      this.loading = false;

    }, () => {
      this.loading = false;
      this.authService.logout(true);
    });
  }

  getDataExtraArray(array: any, buscar: string, valor: string): String {
    if (array == null || array.data_extra == null) {
      return '-';
    }
    for (let i = 0; i < array.data_extra.length; i++) {
      if (buscar === array.data_extra[i].despliegue) {
        return array.data_extra[i][valor] ? array.data_extra[i][valor] : '-';
      }
    }
    return '-';
  }

  openWeightDialog(): void {

    const dialogRef = this.dialog.open(WeightComponent, {
      panelClass: ["modal-meditions"],
      data: {
        data: { ...this.paciente }
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getDataPatient(false)
      }
    });
  }

  openBloodSugarDialog(): void {
    const dialogRef = this.dialog.open(BloodSugarComponent, {
      panelClass: ["modal-meditions"],
      data: {
        data: { ...this.paciente }
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getDataPatient(false)
      }
    });
  }

  openBloodPressureDialog(): void {
    const dialogRef = this.dialog.open(BloodPressureComponent, {
      panelClass: ["modal-meditions"],
      data: {
        data: { ...this.paciente }
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getDataPatient(false)
      }
    });
  }

  openHistorialDialog(tipo: any): void {
    const dialogRef = this.dialog.open(HistorialComponent, {
      panelClass: ["modal-meditions"],
      data: {
        data: { idPaciente: this.paciente.idpaciente },
        tipo: tipo
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) { }
    });
  }

  openGraphic(tipo: any): void {
    let dialogRef;
    if(tipo=='weight' || tipo == 20){
      dialogRef = this.dialog.open(ScreenWeightComponent, {
        panelClass: ["modal-graphic"],
        data: {}
      });
    }else if(tipo=='pressure' || tipo == 57){
      dialogRef = this.dialog.open(ScreenPresionComponent, {
        panelClass: ["modal-graphic"],
        data: {}
      });
    }else{
      dialogRef = this.dialog.open(ScreenGlucoseComponent, {
        panelClass: ["modal-graphic"],
        data: {}
      });
    }

    dialogRef.afterClosed().subscribe(result => {
      if (result) { }
    });
  }
}
