import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JsonResult } from '../../shared/interfaces/json-result.interface';
import { HttpClientModule } from '@angular/common/http';
import { AzLoadersModule } from '../../shared/az-loaders';
import { Color, NgxChartsModule, ScaleType } from '@swimlane/ngx-charts';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { PatientService } from '../services/patient.service';
import { LastMeditionA, Patient, DataExtra } from '../../shared/interfaces/patient.interface';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import moment from 'moment';
import { ValidatorsUtils } from '../../shared/utils/validators.util';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AuthService } from '../../login/services/auth.services';
@Component({
    selector: 'app-screen-glucose',
    standalone: true,
    imports: [HttpClientModule, AzLoadersModule, NgxChartsModule, MatTabsModule, InfiniteScrollModule, MatProgressBarModule],
    templateUrl: './screen-glucose.component.html',
    styleUrls: ['./screen-glucose.component.css'],
    providers: []
})
export class ScreenGlucoseComponent implements OnInit {
    stateChart: any = {
        chart1: false,
        chart2: false,
        chart3: false,
        chart4: false,
        chart5: false
    }
    rangeChart: any = {
        chart2: {
            min: 75,
            max: 140
        },
        chart3: {
            min: 75,
            max: 140
        },
        chart4: {
            min: 75,
            max: 110
        },
        chart5: {
            min: 75,
            max: 110
        }
    }
    itemList: LastMeditionA[] = [];
    selected: number = 0;
    token!: string;
    loading: boolean = true;
    loadingScroll: boolean = true;
    itemsPerPage: number = 300;
    currentPage: number = 0;
    itemsTotal: number = 0;

    // options
    legend: boolean = false;
    showLabels: boolean = true;
    animations: boolean = true;
    xAxis: boolean = true;
    yAxis: boolean = true;
    showYAxisLabel: boolean = true;
    showXAxisLabel: boolean = true;
    xAxisLabel: string = '';
    yAxisLabel: string = '';
    timeline: boolean = true;
    summaryData: any = {
        "averagePercentage": {
            "very_high": 0,
            "high": 0,
            "normal": 0,
            "low": 0
        },
        "mediaGlobal": "0 mg/dL",
        "highValue": "0 mg/dL",
        "lowValue": "0 mg/dL",
        "measurementMoment": {
            "fasting": {
                "classified": "none",
                "title": "Ayuno",
                "media": "0 mg/dL"
            },
            "before_breakfast": {
                "classified": "none",
                "title": "Antes del desayuno",
                "media": "0 mg/dL"
            },
            "after_breakfast": {
                "classified": "none",
                "title": "Despues del desayuno",
                "media": "0 mg/dL"
            },
            "before_lunch": {
                "classified": "none",
                "title": "Antes del almuerzo",
                "media": "0 mg/dL"
            },
            "after_lunch": {
                "classified": "none",
                "title": "Despues del almuerzo",
                "media": "0 mg/dL"
            },
            "before_dinner": {
                "classified": "none",
                "title": "Antes de cenar",
                "media": "0 mg/dL"
            },
            "after_dinner": {
                "classified": "none",
                "title": "Despues de cenar",
                "media": "0 mg/dL"
            }
        }
    };

    colorScheme: Color = {
        domain: ['#69b09f', '#f2aa5e'],
        name: '',
        selectable: false,
        group: "linear" as ScaleType
    };

    colorSchemeBefore: Color = {
        domain: ['#f2aa5e', '#69b09f'],
        name: '',
        selectable: false,
        group: "linear" as ScaleType
    };

    colorSchemeResumen: Color = {
        domain: ['#bb3707', '#d65959', '#69b09f', '#f2aa5e'],
        name: '',
        selectable: false,
        group: "linear" as ScaleType
    };

    customColors = [
        { name: 'Antes de comer', value: '#69b09f' },
        { name: 'Despues de comer', value: '#f2aa5e' },
        { name: 'none', value: 'transparent' }
    ];
    series: any[] = [
        {
            "name": "Muy alto",
            "value": 0,
            "label": "0%"
        }
    ];

    multi: any = {
        "glucose_date": [],
        "glucose_hour": [],
        "glucose_before_eating": [],
        "glucose_after_eating": []
    };
    xAxisTicksDay: any[] = []
    xAxisTicksDayBefore: any[] = []
    xAxisTicksDayAfter: any[] = []
	timeout: number = 100;

    constructor(
        public authService: AuthService,
        private activatedRouter: ActivatedRoute,
        private patienService: PatientService,
        private utils: ValidatorsUtils
    ) {
        moment.locale("es");
    }

    ngOnInit(): void {
        this.activatedRouter.queryParams.subscribe((trae) => {
            let { tokenApp } = trae;
            this.token = tokenApp
            if(!tokenApp && this.authService._token){
                this.token = this.authService._token;
            }
            if (this.token) {
                this.getDataDailyAverageGlucose();
                this.getDataDailyMeasurement(-7, false);
                this.getHistoryMeasurements(false);
            }
        });
    }

    onScroll() {
        if (!this.loadingScroll) {
            this.currentPage++;
            if (this.itemList.length < this.itemsTotal)
                this.getHistoryMeasurements();
        }
    }

    getHistoryMeasurements(loading: boolean = false) {
        this.loadingScroll = true;
        if (loading)
            this.itemList = [];
        this.patienService.getHistoryMeasurements(this.token, 'blood_glucose', this.currentPage, this.itemsPerPage)
            .subscribe((res: JsonResult) => {
                let response = res.retObject;
                this.itemList = this.itemList.concat(response.data);
                this.itemsTotal = response.total;
                this.loadingScroll = false;
            }, (error: any) => {
                this.loadingScroll = false;
                console.error(error);
            });
    }

    getDataDailyMeasurement(end: number, state: boolean = true) {
        let url = '/measurement-time?start=' + moment().add(end, 'days').format("YYYY-MM-DD") + '&end=' + moment().format("YYYY-MM-DD");
        this.patienService.getDataDailyMeasurement('blood_glucose', this.token, url).subscribe((trae: JsonResult) => {
            let response = trae.retObject.data;
            let dataGraficAfter: any = [];
            let dataGraficBefore: any = [];
            let indexStep = null
            this.xAxisTicksDay = [];
            for (let index = 0; index < response.after_eating.length; index++) {
                dataGraficAfter.push({ "name": response.after_eating[index].date, "value": response.after_eating[index].value });
                if (!this.xAxisTicksDay[0]) {
                    this.xAxisTicksDay[0] = response.after_eating[index].date
                }
                if (indexStep == null || indexStep < index) {
                    this.xAxisTicksDay[1] = response.after_eating[index].date
                    indexStep = index
                }
            }


            for (let index = 0; index < response.before_eating.length; index++) {
                dataGraficBefore.push({ "name": response.before_eating[index].date, "value": response.before_eating[index].value });
                if (!this.xAxisTicksDay[0]) {
                    this.xAxisTicksDay[0] = response.before_eating[index].date
                }
                if (indexStep == null || indexStep < index) {
                    this.xAxisTicksDay[1] = response.before_eating[index].date
                }
            }

            this.utils.calcMinAndMAx(this.rangeChart, "chart2", dataGraficBefore.concat(dataGraficAfter), 2);
            this.utils.calcMinAndMAx(this.rangeChart, "chart4", dataGraficBefore, 2);
            this.utils.calcMinAndMAx(this.rangeChart, "chart5", dataGraficAfter, 2);

            if (!dataGraficAfter.length && !dataGraficBefore.length) {
                dataGraficAfter.push({ "name": trae.retObject.start_format, "value": 0 }, { "name": trae.retObject.end_format, "value": 0 });
                dataGraficBefore.push({ "name": trae.retObject.start_format, "value": 0 }, { "name": trae.retObject.end_format, "value": 0 });
                this.xAxisTicksDay = [trae.retObject.start_format, trae.retObject.end_format]
            }

            if (dataGraficAfter.length) {
                this.xAxisTicksDayAfter[0] = dataGraficAfter[0].name;
                this.xAxisTicksDayAfter[1] = dataGraficAfter[dataGraficAfter.length - 1].name;
            }
            if (dataGraficBefore.length) {
                this.xAxisTicksDayBefore[0] = dataGraficBefore[0].name;
                this.xAxisTicksDayBefore[1] = dataGraficBefore[dataGraficBefore.length - 1].name;
            }

            this.multi['glucose_date'] = [{ "name": "Antes de comer", "series": dataGraficBefore }, { "name": "Despues de comer", "series": dataGraficAfter }];

            this.multi['glucose_before_eating'] = [{ "name": "Antes de comer", "series": dataGraficBefore }];
            this.multi['glucose_after_eating'] = [{ "name": "Despues de comer", "series": dataGraficAfter }];
            this.stateChart.chart2 = state;
            this.stateChart.chart4 = state;
            this.stateChart.chart5 = state;
            this.getDataTimeMeasurement(end, state);
        }, () => { });
    }

    getDataTimeMeasurement(end: number, state: boolean = true) {
        let url = '?start=' + moment().add(end, 'days').format("YYYY-MM-DD") + '&end=' + moment().format("YYYY-MM-DD");
        this.patienService.getDataTimeMeasurement('blood_glucose', this.token, url).subscribe((trae: JsonResult) => {
            let response = trae.retObject.data;
            let dataGraficAfter: any = [];
            let dataGraficBefore: any = [];
            response.after_eating.forEach((item: any) => {
                dataGraficAfter.push({ "name": `${item.time}`, "value": item.value });
            });

            response.before_eating.forEach((item: any) => {
                dataGraficBefore.push({ "name": `${item.time}`, "value": item.value });
            });
            let bodyChar = [
                { "name": "Antes de comer", "series": dataGraficBefore },
                { "name": "Despues de comer", "series": dataGraficAfter }
            ];
            this.utils.calcMinAndMAx(this.rangeChart, "chart3", dataGraficBefore.concat(dataGraficAfter), 2);
            this.multi['glucose_hour'] = bodyChar;
            this.stateChart.chart3 = state;
        }, () => { });
    }
    tabSelectedParent(e: MatTabChangeEvent) {
        if (e.index == 1 && this.selected == 0) {
            setTimeout(() => {
                this.stateChart = {
                    chart1: true,
                    chart2: true,
                    chart3: true,
                    chart4: true,
                    chart5: true
                }
            }, this.timeout);
        }
    }
    tabSelected(e: MatTabChangeEvent) {
        this.stateChart = {
            chart1: true,
            chart2: false,
            chart3: false,
            chart4: false,
            chart5: false
        }
        this.rangeChart = {
            chart2: {
                min: 75,
                max: 140
            },
            chart3: {
                min: 75,
                max: 140
            },
            chart4: {
                min: 75,
                max: 110
            },
            chart5: {
                min: 75,
                max: 110
            }
        }
        let option = [-7, -30, -90];

        this.getDataTimeMeasurement(option[e.index]);
        this.getDataDailyMeasurement(option[e.index]);
    }

    getDataDailyAverageGlucose() {
        this.patienService.getDataDailyAverageGlucose(this.token)
            .subscribe((res: JsonResult) => {
                let response = res.retObject;
                this.summaryData = response.data;
                if (this.summaryData.averagePercentage) {
                    this.series = [
                        {
                            "name": "Muy alto",
                            "value": this.summaryData.averagePercentage.notNormal,
                            "label": this.summaryData.averagePercentage.notNormal + "%"
                        },
                        {
                            "name": "Alto",
                            "value": this.summaryData.averagePercentage.high,
                            "label": this.summaryData.averagePercentage.high + "%"
                        },
                        {
                            "name": "Normal",
                            "value": this.summaryData.averagePercentage.normal,
                            "label": this.summaryData.averagePercentage.normal + "%"
                        },
                        {
                            "name": "Bajo",
                            "value": this.summaryData.averagePercentage.low,
                            "label": this.summaryData.averagePercentage.low + "%"
                        }
                    ]
                    this.stateChart.chart1 = true;
                }
            }, (error: any) => {
                console.error(error);
            });
    }

    searchItemExtra(id: number, items: DataExtra[]): DataExtra | null {
        for (const item of items) {
            if (item.id == id) {
                return item;
            }
        }
        return null;
    }
}
